import {W, _, P, D, O, K, T} from '../constants/gameConstants';
import {Level} from '../types';

export const LEVEL_2: Level = [
  [W, W, W, W, W, W, W, W, W, W, W, W, W],
  [W, P, _, _, _, _, _, _, _, _, _, W, W],
  [W, _, O, W, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, D, W, _, W],
  [W, _, T, _, _, _, _, _, _, _, _, _, W],
  [W, _, W, _, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, W, W],
  [W, _, O, _, _, _, _, _, _, _, _, _, W],
  [W, _, W, W, _, _, _, _, _, _, _, K, W],
  [W, _, _, _, _, _, _, _, _, _, _, W, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, W, _, _, _, _, _, _, _, _, _, _, W],
  [W, W, W, W, W, W, W, W, W, W, W, W, W],
];
