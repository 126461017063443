import {W, _, P, D, O, K, T, X, Y} from '../constants/gameConstants';
import {Level} from '../types';

export const LEVEL_7: Level = [
  [W, W, W, W, _, _, W, _, W, W, W, W, W],
  [W, W, _, W, _, _, _, _, _, _, _, W, W],
  [_, X, _, T, _, _, _, _, _, _, _, W, W],
  [W, _, _, _, O, _, _, _, W, _, _, _, W],
  [W, _, _, _, D, _, _, _, _, _, _, _, W],
  [_, _, _, _, _, _, _, _, _, _, _, X, _],
  [W, _, _, _, _, Y, P, _, _, _, _, _, W],
  [_, _, _, _, _, _, X, _, _, _, _, _, _],
  [W, _, W, _, _, _, _, _, _, _, _, _, W],
  [W, _, W, W, _, _, O, _, _, _, W, _, W],
  [_, _, W, _, _, _, _, _, _, _, _, K, _],
  [W, W, W, _, _, _, _, Y, _, _, W, O, W],
  [W, W, W, W, _, _, W, _, W, W, W, W, W],
];
