import {W, _, P, D, K, T, X, Y} from '../constants/gameConstants';
import {Level} from '../types';

export const LEVEL_9: Level = [
  [_, _, _, W, _, _, _, _, _, _, _, _, _],
  [W, _, _, _, X, T, X, _, _, _, _, _, _],
  [_, _, Y, _, _, W, _, _, _, _, _, _, _],
  [_, _, _, _, _, _, _, _, W, _, _, _, _],
  [_, _, _, _, _, _, _, _, _, _, _, W, _],
  [_, _, _, _, _, _, W, K, _, _, _, _, _],
  [_, _, _, _, _, _, P, _, _, _, _, _, _],
  [_, _, _, _, _, _, X, _, _, _, _, _, _],
  [_, _, _, _, _, _, _, _, _, _, _, _, _],
  [_, W, _, _, _, _, _, _, _, _, Y, _, _],
  [_, _, _, _, _, _, _, _, _, W, _, _, _],
  [_, _, _, _, _, _, _, _, _, _, _, _, W],
  [_, _, _, D, W, _, _, _, _, _, _, _, _],
];
