import {W, _, P, D, O, K, L, T} from '../constants/gameConstants';
import {Level} from '../types';

export const LEVEL_1: Level = [
  [W, W, W, W, W, W, W, W, W, W, W, W, W],
  [W, L, _, _, _, _, _, _, _, _, _, K, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, O, _, _, _, _, P, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, _, _, _, _, _, _, _, _, _, _, _, W],
  [W, D, _, _, _, _, _, _, _, _, _, T, W],
  [W, W, W, W, W, W, W, W, W, W, W, W, W],
];
